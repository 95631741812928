import { ISelect } from '../protocols/Tributacao';

export const tipoEncerramento: ISelect[] = [
  {
    value: 0,
    label: 'Entrada',
  },
  {
    value: 1,
    label: 'Saída',
  },
];
