import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { InputPercent, InputSelect, InputText } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { formatCurrencyAsText } from '~/utils/functions';
import useDebounce from '~/hooks/useDebounce';
import { useQueryClient } from 'react-query';

import {
  tipoEncerramento,
  tipoTributacoes,
  tipoTributacoesSimples,
} from './data';
import { Select } from './protocols';
import { ISelect } from './protocols/Tributacao';
import { Container } from './styles';
import { schema } from './Validations';
import { LojaContext } from '~/context/loja';

const Tributacao: React.FC = () => {
  const queryClient = useQueryClient();
  const { loja } = useContext(LojaContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    unregister,
    clearErrors,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const { debouncedFn } = useDebounce();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const [optionsTipoTributacao, setOptionsTipoTributacao] = useState<ISelect[]>(
    [],
  );

  const [isShowDiferido, setIsShowDiferido] = useState(false);
  const [isShowICMS, setIsShowICMS] = useState(false);
  const [isShowReducao, setIsShowReducao] = useState(false);
  const [isShowCSON, setisShowCSON] = useState(false);
  const [flgTribFornSimples, setFlgTribFornSimples] = useState(false);
  const [flgAliquotaIss, setFlgAliquotaIss] = useState(false);
  const [tipoRegime, setTipoRegime] = useState<number | null>(null);

  const [tipoEncerramentoDiferido, setTipoEncerramentoDiferido] =
    useState<Select>({
      value: undefined,
      label: undefined,
    });

  useEffect(() => {
    (async () => {
      handleTipoRegime(loja.tipo_regime);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      handleTipoRegime(loja.tipo_regime);
    })();
  }, [loja.cod_loja]);

  useEffect(() => {
    (async () => {
      handleTipoRegime();
    })();
  }, [queryClient.getQueryData(`tipo_regime_loja_${loja.cod_loja}`)]);

  const handleTipoRegime = (tipo_regime?: number) => {
    const tipo_regime_loja: any =
      tipo_regime !== undefined
        ? tipo_regime
        : queryClient.getQueryData(`tipo_regime_loja_${loja.cod_loja}`);

    if (tipo_regime_loja !== undefined) {
      setTipoRegime(tipo_regime_loja);
      setValue('tipo_regime', tipo_regime_loja);
      if (Number(tipo_regime_loja) === 2) {
        setValue('isShowCSON', true);
        setisShowCSON(true);
      } else {
        setValue('cod_situacao_tributaria_simples', '');
        clearErrors('cod_situacao_tributaria_simples');

        setValue('isShowCSON', false);
        setisShowCSON(false);
      }

      queryClient.removeQueries(`tipo_regime_loja_${loja.cod_loja}`);
    }
  };

  useEffect(() => {
    const tipoTrib =
      tipoRegime === 0
        ? tipoTributacoes.filter((tipo) => tipo.label !== 'Imune')
        : tipoTributacoes;

    const options = !flgTribFornSimples ? tipoTrib : tipoTributacoesSimples;

    setOptionsTipoTributacao(options);

    if (!isUpdate) {
      setValue('tipo_encerramento_diferido', {
        value: undefined,
        label: '',
      });
      setTipoEncerramentoDiferido({
        value: undefined,
        label: '',
      });
      setIsShowICMS(false);
      setValue('isShowICMS', false);
      setIsShowDiferido(false);
      setValue('isShowDiferido', false);
      setIsShowReducao(false);
    }
  }, [flgTribFornSimples, setValue, tipoRegime]);

  useEffect(() => {
    if (!isShowDiferido) {
      unregister('tipo_encerramento_diferido');
      setTipoEncerramentoDiferido({ value: undefined, label: '' });
    }
  }, [isShowDiferido, unregister]);

  useEffect(() => {
    if (!isShowICMS) {
      unregister('val_icms');
    }
  }, [isShowICMS, unregister]);

  useEffect(() => {
    if (!isShowReducao) {
      unregister('val_reducao');
    }
  }, [isShowReducao, unregister]);

  /**
   * Passa os dados da tabela para o formulário
   */
  const onRowClick = (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_tributacao,
      des_tributacao,
      val_icms,
      tipo_tributacao,
      val_reducao,
      tipo_encerramento_diferido,
      flg_trib_forn_simples,
      cod_situacao_tributaria,
      cod_situacao_tributaria_simples,
      cod_tributacao_pdv,
      flg_aliquota_iss,
    } = row;

    const valReducao =
      val_reducao === 0 ? '0,00' : formatCurrencyAsText(val_reducao);

    setValue('cod_tributacao', cod_tributacao);
    setValue('des_tributacao', des_tributacao);
    setValue('flg_trib_forn_simples', flg_trib_forn_simples);
    debouncedFn(() => {
      setValue(
        'tipo_tributacao',
        tipoTributacoes.find(
          (option: Select) => option.value === tipo_tributacao,
        ),
      );
    }, 10);

    setTimeout(() => {
      const tipoEncerramentoOption = tipoEncerramento.find(
        (item: Select) => item.value === tipo_encerramento_diferido,
      );
      setValue('tipo_encerramento_diferido', tipoEncerramentoOption);
      if (tipoEncerramentoOption) {
        setTipoEncerramentoDiferido(tipoEncerramentoOption);
      }
    }, 100);

    setValue('val_icms', formatCurrencyAsText(val_icms));
    setValue('val_reducao', valReducao);
    setValue('cod_situacao_tributaria', cod_situacao_tributaria);
    setValue(
      'cod_situacao_tributaria_simples',
      cod_situacao_tributaria_simples,
    );
    setValue('cod_tributacao_pdv', cod_tributacao_pdv);
    setValue('flg_aliquota_iss', flg_aliquota_iss);

    setFlgTribFornSimples(flg_trib_forn_simples);

    setTipoEncerramentoDiferido({
      value: tipo_encerramento_diferido,
      label: '',
    });
    setFlgAliquotaIss(flg_aliquota_iss);
    toggleFields(tipo_tributacao);
    setIsUpdate(true);
    setShowSearch(false);
  };

  const newData = () => {
    handleClearform();
    setIsUpdate(false);
    setShowSearch(false);
  };

  const toggleFields = useCallback(
    (tipoTributacao: string | number | undefined) => {
      if (tipoTributacao === 2 || tipoTributacao === 3) {
        setIsShowReducao(true);
        setValue('isShowReducao', true);
      } else {
        setIsShowReducao(false);
        setValue('isShowReducao', false);
      }

      if (
        tipoTributacao === 1 ||
        tipoTributacao === 4 ||
        tipoTributacao === 8
      ) {
        setIsShowICMS(false);
        setValue('isShowICMS', false);
      } else {
        setIsShowICMS(true);
        setValue('isShowICMS', true);
      }

      if (tipoTributacao === 5) {
        setIsShowDiferido(true);
        setValue('isShowDiferido', true);
      } else {
        setIsShowDiferido(false);
        setValue('isShowDiferido', false);
      }
    },
    [setValue],
  );

  const handleChangeTipoTributacao = useCallback(
    (value: Select) => {
      setValue('tipo_tributacao', value);
      setValue('val_reducao', undefined);
      setValue('tipo_encerramento_diferido', { value: undefined, label: '' });

      toggleFields(value.value);
    },
    [setValue, toggleFields],
  );

  /**
   * Limpar Formulário
   */
  const handleClearform = useCallback(() => {
    reset();

    setTipoEncerramentoDiferido({
      value: undefined,
      label: undefined,
    });
    setValue('tipo_tributacao', null);
    setValue('des_tributacao', '');
    setValue('isShowDiferido', false);
    setIsShowDiferido(false);
    setIsShowICMS(false);
    setValue('isShowICMS', false);
    setIsShowReducao(false);
    setValue('isShowReducao', false);
    setFlgTribFornSimples(false);
    setValue('flg_trib_forn_simples', false);
    setValue('flg_aliquota_iss', false);
    setFlgAliquotaIss(false);
    setValue('cod_situacao_tributaria', '');
    setValue('cod_tributacao_pdv', '');
    setValue('cod_situacao_tributaria_simples', '');
  }, [reset, setValue]);

  const onReturnSearch = () => {
    handleClearform();
    setIsUpdate(false);
    setShowSearch(true);
  };

  useEffect(() => {
    (() => {
      if (
        String(errors.cod_situacao_tributaria?.message)?.includes(
          'CST incompatível',
        )
      ) {
        return toast.warning(errors.cod_situacao_tributaria?.message);
      }

      if (
        String(errors.cod_situacao_tributaria_simples?.message)?.includes(
          'CSOSN incompatível',
        ) &&
        tipoRegime === 2
      ) {
        return toast.warning(errors.cod_situacao_tributaria_simples?.message);
      }
    })();
  }, [errors]);

  useEffect(() => {
    const codSituacaoTributaria = getValues('cod_situacao_tributaria');
    const tipoTributacao = getValues('tipo_tributacao');

    const checkSubstituicao =
      codSituacaoTributaria === '070' && tipoTributacao.value === 3;

    const validCondition =
      checkSubstituicao && errors.val_reducao?.message
        ? true
        : !!errors.val_reducao?.message;

    if (!isUpdate && validCondition)
      toast.warning('Valor de Redução deve ser informado');
  }, [errors.val_reducao, isUpdate, getValues]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    delete data.tipo_regime;

    if (data.cod_situacao_tributaria_simples === '' && tipoRegime === 2) {
      setIsLoading(false);
      setError('cod_situacao_tributaria_simples', { type: 'required' });
      return;
    }
    try {
      if (!data.val_icms) data.val_icms = '0';

      if (!data.val_reducao) data.val_reducao = '0';
      if (!isUpdate) {
        const res = await api.post('/tributacao', data);
        const { message } = res.data;
        handleClearform();
        onReturnSearch();
        return toast.success(message);
      }
      const codigo = getValues('cod_tributacao');
      const res = await api.put(`/tributacao/${codigo}`, data);
      const { message } = res.data;
      onReturnSearch();
      return toast.success(message);
    } finally {
      setIsLoading(false);
    }
  });

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const codigo = getValues('cod_tributacao');
      const res = await api.delete(`/tributacao/${codigo}`);
      const { success, message } = res.data;
      if (success) {
        handleClearform();
        onReturnSearch();
        toast.success(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={12} newData={newData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={12}
          title="Cadastro de Tributação"
          codigoRegistro={[
            {
              value: getValues('cod_tributacao'),
              des_campo: 'Código',
            },
          ]}
          onSave={onSubmit}
          onDelete={onDelete}
          onNew={newData}
          onCancel={onReturnSearch}
          onClearFields={handleClearform}
          onReturnSearch={onReturnSearch}
          isUpdate={isUpdate}
        >
          <Row>
            <Col sm={12}>
              <InputText
                label="Descrição"
                placeholder="Informe a Descrição"
                maxLength={100}
                name="des_tributacao"
                control={control}
                caseInput="upper"
                register={register}
                isError={!!errors.des_tributacao}
              />
            </Col>
            <Col sm={12}>
              <Separator sidePadding="0 0" labelText="Simples Nacional" />
            </Col>
            <Col sm={12}>
              <ToggleDefault
                setChecked={flgTribFornSimples}
                labelText="Trib. para Fornecedor Optante Simples?"
                onSwitch={() => {
                  if (!isUpdate) {
                    setValue('isShowICMS', false);
                    setIsShowICMS(false);
                  }
                  setValue('flg_trib_forn_simples', !flgTribFornSimples);
                  setFlgTribFornSimples(!flgTribFornSimples);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6} xl={4}>
              <InputSelect
                label="Tipo de Tributação"
                disabled={isUpdate}
                placeholder="Selecione o Tipo de Tributação"
                name="tipo_tributacao"
                register={register}
                control={control}
                isError={!!errors.tipo_tributacao}
                options={optionsTipoTributacao}
                changeSelected={(value) => {
                  clearErrors('tipo_tributacao');
                  handleChangeTipoTributacao(value);
                }}
              />
            </Col>
            {isShowDiferido && (
              <Col sm={12} md={4} lg={3}>
                <InputSelect
                  label="Tipo&nbsp;do&nbsp;Encerramento&nbsp;(Diferido)"
                  disabled={isUpdate}
                  placeholder="Selecione o Tipo do Encerramento"
                  name="tipo_encerramento_diferido"
                  register={register}
                  control={control}
                  isError={!!errors.tipo_encerramento_diferido}
                  options={tipoEncerramento}
                  value={tipoEncerramentoDiferido?.label || ''}
                  changeSelected={(value) => {
                    setValue('tipo_encerramento_diferido', value);
                    setTipoEncerramentoDiferido(value);
                  }}
                />
              </Col>
            )}

            {isShowICMS && (
              <Col sm={12} md={3} lg={2}>
                <InputPercent
                  label="Valor ICMS"
                  name="val_icms"
                  register={register}
                  control={control}
                  isError={!!errors.val_icms}
                  isDisabled={isUpdate}
                  min={0}
                  max={100}
                  placeholder="0,00"
                />
              </Col>
            )}

            {isShowReducao && (
              <Col sm={12} md={3} lg={2}>
                <InputPercent
                  label="Redução BC"
                  name="val_reducao"
                  register={register}
                  control={control}
                  isDisabled={isUpdate}
                  isError={!!errors.val_reducao}
                  min={0}
                  max={100}
                  placeholder="0,00"
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={12} md={5} lg={4}>
              <Separator sidePadding="0 0" labelText="Situação Tributária" />
              <Row>
                <Col sm={12} md={11}>
                  <InputText
                    label="Não Optante Simples (CST)"
                    minLength={2}
                    maxLength={3}
                    placeholder="Informe os 3 dígitos do CST"
                    register={register}
                    control={control}
                    name="cod_situacao_tributaria"
                    isError={!!errors.cod_situacao_tributaria}
                  />
                  <input
                    {...register('tipo_regime')}
                    value={tipoRegime || 0}
                    hidden
                  />
                </Col>
                {isShowCSON && (
                  <Col sm={12} md={6}>
                    <InputText
                      label="Optante Simples (CSOSN)"
                      minLength={4}
                      maxLength={4}
                      register={register}
                      control={control}
                      name="cod_situacao_tributaria_simples"
                      isError={!!errors.cod_situacao_tributaria_simples}
                      placeholder="Digite aqui o CSOSN"
                    />
                  </Col>
                )}
              </Row>
            </Col>

            <Col sm={12} md={6} lg={8} xl={6}>
              <Separator sidePadding="0 0" labelText="PDV" />
              <Row>
                <Col sm={9} md={7} lg={5}>
                  <InputText
                    placeholder="Informe a Tributação"
                    maxLength={6}
                    label="Tributação PDV"
                    register={register}
                    control={control}
                    name="cod_tributacao_pdv"
                    isError={!!errors.cod_tributacao_pdv}
                  />
                </Col>
                <Col
                  sm={3}
                  style={{
                    marginTop: '8px',
                  }}
                >
                  <ToggleDefault
                    setChecked={flgAliquotaIss}
                    labelText="Alíquota&nbsp;ISS?"
                    onSwitch={() => {
                      setValue('flg_aliquota_iss', !flgAliquotaIss);
                      setFlgAliquotaIss(!flgAliquotaIss);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default Tributacao;
