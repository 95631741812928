interface RulesMap {
  [tipo_regime: number]: {
    [situacaoTributaria: string]: number[];
  };
}

/**
 * relacao entre tipo do regime com o tipo de tributacao e st
 *
 * ex: tipo regime 0: cst '000' deve estar com o
 * tipo de tributacao [0] - "Tributado"
 *
 * tipo regime
 * 0: Lucro Real
 * 1: Lucro Presumido
 * 2: Optante Simples
 */

export const rulesMap: RulesMap = {
  0: {
    '000': [0],
    '010': [3],
    '020': [2],
    '030': [3],
    '040': [1],
    '041': [8],
    '050': [4],
    '051': [5],
    '060': [3],
    '070': [3],
    '090': [6],
  },
  2: {
    '0101': [0],
    '0102': [0],
    '0103': [1],
    '0201': [3],
    '0202': [3],
    '0203': [3],
    '0300': [9],
    '0400': [8],
    '0500': [3],
    '0900': [6],
  },
};
