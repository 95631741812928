import { ISelect } from '../protocols/Tributacao';

export const tipoTributacoes: ISelect[] = [
  {
    value: 0,
    label: 'Tributado',
  },
  {
    value: 1,
    label: 'Isento',
  },
  {
    value: 2,
    label: 'Redução',
  },
  {
    value: 3,
    label: 'Substituição',
  },
  {
    value: 4,
    label: 'Suspensão',
  },
  {
    value: 5,
    label: 'Diferido',
  },
  {
    value: 6,
    label: 'Outros ICMS',
  },
  {
    value: 7,
    label: 'Portaria CAT-38',
  },
  {
    value: 8,
    label: 'Não Tributado',
  },
  {
    value: 9,
    label: 'Imune',
  },
];

export const tipoTributacoesSimples: ISelect[] = [
  {
    value: 0,
    label: 'Tributado',
  },
  {
    value: 2,
    label: 'Redução',
  },
];
